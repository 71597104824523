import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import {
  changeOperatorChatText,
  changeOperatorMessageContentType,
} from "src/app/chats-panel/~store/actions/actions";
import TagSearchingInput from "src/app/shared/components/tag-searching-input/TagSearchingInput";
import {
  ISearchingTag,
  TagName,
  TagType,
} from "src/app/shared/components/tag-searching-input/~types/models/SearchingTag";
import { IApplicationState } from "src/~store/models/ApplicationState";
import {
  getTopicPairCategoriesByTopicName,
  getTopicPairsByTopicNameWithFilter,
  saveTopicPairUsedTag,
  logOperatorAnswer,
} from "../../~store/actions/actions";
import {
  topicPairCategoriesSelector,
  topicPairSourcesSelector,
  topicSearchSelector
} from "../../~store/selectors";
import { channelSettingSelector, selectedChatSelector } from "src/app/chats-panel/~store/selectors";
import { ITopicItemSearchProps } from "../topic-item/~types/TopicItemSearchProps";
import { getNlpSearchingTags, TOPIC_PAIRS_ON_PAGE } from "./../../~store/actions/constants";
import { useTranslation } from "react-i18next";

import "./TopicItemSearch.css";
import { ITopicPair } from "../../~store/models/TopicPair";
import { operatorCurrentChatTextSelector, operatorStatusSelector } from "src/app/chats-panel/~store/selectors";
import {
  TopicPairContentType,
  topicPairContentTypeToString,
} from "../../~store/models/enums/TopicPairContentType";
import { ConvertTextToContent } from "src/app/shared/components/text-to-content/TextToContent";
import Paging from "src/app/shared/components/paging/Paging";
import { OperatorStatusType } from "../../../chats-panel/~store/state/ChatItemsState";
import { showErrorToastr } from "../../../shared/helpers/toastr-helper/ToastrHelper";

type ReduxType = ReturnType<typeof mapStateToProps> & ITopicItemSearchProps;

const TopicItemSearch = (props: ReduxType) => {
  const { t } = useTranslation();
  const [topicName, setTopicName] = useState<string>();
  const [currentTags, setCurrentTags] = useState<ISearchingTag[]>();
  const [selectedPage, setSelectedPage] = React.useState<number>(1);
  const tableRef = useRef<HTMLDivElement | null>(null);

  const location = useLocation();

  useEffect(() => {
    getTopicName();
  }, []);

  useEffect(() => {
    getTopicName();

    if ((location.state as any)?.theme.length > 0) {
      const openingTag: ISearchingTag = {
        id: 0,
        value: t("common.phrase"),
        tagType: TagType.OpeningTag,
        tagName: TagName.Phrase,
      };
      const operationTag: ISearchingTag = {
        id: 1,
        value: "=",
        tagType: TagType.OperationTag,
      };
      const closingTag: ISearchingTag = {
        id: 2,
        value: (location.state as any)?.theme,
        tagType: TagType.ClosingTag,
        tagName: TagName.Phrase,
      };

      const tags: ISearchingTag[] = [openingTag, operationTag, closingTag];

      setCurrentTags(tags);
    } else {
      setCurrentTags([]);
    }
  }, [(location.state as any)?.time]);

  useEffect(() => {
    if (topicName) {
      props.getTopicPairCategoriesByTopicName(topicName);
      props.getTopicPairsByTopicNameWithFilter(
        topicName,
        currentTags || [],
        selectedPage
      );
    }
  }, [topicName]);

  useEffect(() => {
    if (topicName)
      props.getTopicPairsByTopicNameWithFilter(
        topicName,
        currentTags || [],
        selectedPage
      );
  }, [currentTags]);

  useLayoutEffect(() => {
    const items = tableRef.current?.querySelectorAll(
      ".topic-pairs-search-table__item"
    );
    items?.forEach((item) => {
      const outerHight =
        item.querySelector<HTMLDivElement>(".topic-pairs-search-answer-text")
          ?.offsetHeight || 0;
      const innerHeight =
        item.querySelector<HTMLDivElement>(".topic-pairs-search-answer-text div")
          ?.offsetHeight || 0;
      const button = item.querySelector<HTMLDivElement>(
        ".topic-pairs-search-table__item-collapse-button"
      );
      if (button && outerHight > innerHeight) {
        button.style.display = "none";
      }
    });
  });

  const toggleShowAnswerText = (id: number) => {
    const container = document.querySelector<HTMLDivElement>(
      `div[data-topic-pairs-item-id="${id}"]`
    );
    if (container) {
      const text = container.querySelector<HTMLDivElement>(
        ".topic-pairs-search-answer-text"
      );
      const button = container.querySelector<HTMLDivElement>(
        ".topic-pairs-search-table__item-collapse-button"
      );
      text?.classList.toggle("opened");
      button?.classList.toggle("opened");
    }
  };

  const getTopicName = () => {
    let encodedTopicname = window.location.href.split("/nlp/search/")[1];    
    let decodedTopicname = decodeURIComponent(encodedTopicname)
    setTopicName(decodedTopicname);
  };

  const _onSendButtonClick = (topicPair: ITopicPair) => {
    if (
      topicPair.answer?.text &&
      props.currentMessageText != topicPair.answer.text
    ) {
      props.changeOperatorChatText(topicPair.answer.text);
      props.changeOperatorMessageContentType(topicPair.contentType);
      props.saveTopicPairUsedTag(topicPair, topicName === getNlpAnswerTemplateTopicName());
      props.logOperatorAnswer(topicPair, topicName, currentTags);
    }
  };

  const getNlpAnswerTemplateTopicName = () => {
    const channelSettingObj = props.channelSettings?.value
      ? JSON.parse(props.channelSettings.value)
      : undefined;
    const locale = props.selectedChat?.messenger.contact.locale;
    return `${channelSettingObj.NLP_AnswerTemplate}_${locale}`;
  }

  const _mapTopicPairsTable = () => {
    return (
      <div className="topic-pairs-search-table">
        <div className="topic-pairs-search-table__header">
          {/* <div /> */}
          {/* <div>{t("chat.subject")}</div>
          <div>{t("nlp.theme")}</div> */}
          <div>{t("nlp.question")}</div>
          <div>{t("nlp.answer")}</div>
          <div />
        </div>
        <div className="topic-pairs-search-table__body" ref={tableRef}>
          {props.topicSearch.topicPairs?.map((item) => {
            return (
              <div
                className="topic-pairs-search-table__item"
                key={item.topicPairId}
                data-topic-pairs-item-id={item.topicPairId}
              >
                <div className="topic-pairs-search-table__item_info">
                  {item.topicPairId && (
                    <div className="topic-pairs-search-id tag">
                      #{item.topicPairId}
                    </div>
                  )}
                  {item.source && (
                    <div className="topic-pairs-search-source tag">
                      {item.source}
                    </div>
                  )}
                  {item.subject && (
                    <div className="topic-pairs-search-category tag">
                      {item.subject}
                    </div>
                  )}
                  {item.contentType && (
                    <div className="topic-pairs-search-content-type tag">
                      {topicPairContentTypeToString(item.contentType)}
                    </div>
                  )}
                </div>
                <div className="topic-pairs-search-table__item-main">
                  <div className="topic-pairs-search-question">
                    <div>
                      <b>{item.questions.find((q) => q.isDefault)?.text}</b>
                    </div>
                    <ul className="topic-pairs-search-question__list">
                      {item.questions
                        .filter((q) => !q.isDefault)
                        .map((q, i) => {
                          return <li key={i}>{q.text}</li>;
                        })}
                    </ul>
                  </div>
                  <div className="topic-pairs-search-answer-text">
                    {ConvertTextToContent(
                      item.contentType,
                      item.answer?.text ?? "",
                      false
                    )}
                  </div>
                  <div className="topic-pairs-search-button">
                    <Button
                        onClick={() => {
                            if (props.operatorStatus === OperatorStatusType.Offline) {
                                showErrorToastr(t('nlp.errorSendNLPSearchWhenOperatorOffline'))
                            }
                            else {
                                _onSendButtonClick(item);
                            }
                      }}
                    >
                      {t("common.send")}
                    </Button>
                  </div>
                </div>
                {
                  <div
                    className="topic-pairs-search-table__item-collapse-button"
                    onClick={() => toggleShowAnswerText(item.topicPairId)}
                  />
                }
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const _mapFilter = () => {
    return (
      <div className="topic-pairs-search-input">
        <TagSearchingInput
          searchingTags={getNlpSearchingTags(
            props.topicPairCategories,
            props.topicPairSources
          )}
          onSearch={_onSearchClicked}
          repeatingTags={false}
          currentTags={currentTags || []}
          onClearFilter={() => setCurrentTags([])}
        />
      </div>
    );
  };

  const _onSearchClicked = (tags: ISearchingTag[]) => {
    setSelectedPage(1);
    setCurrentTags(tags);
  };

  return (
    <div className="topic-search-container">
      <div className="topic-search-header">{topicName}</div>
      {_mapFilter()}
      <Paging
        countOfElements={props.topicSearch.topicPairsCount}
        countOnPages={TOPIC_PAIRS_ON_PAGE}
        onTileClick={(sp) => {
          if(topicName) {
            setSelectedPage(sp);
            props.getTopicPairsByTopicNameWithFilter(
              topicName,
              currentTags || [],
              sp
            );
          }
        }}
        selectedPage={selectedPage}
      />
      {_mapTopicPairsTable()}
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    topicSearch: topicSearchSelector(state),
    topicPairCategories: topicPairCategoriesSelector(state),
    topicPairSources: topicPairSourcesSelector(state),
    currentMessageText: operatorCurrentChatTextSelector(state),
    operatorStatus: operatorStatusSelector(state),
    channelSettings: channelSettingSelector(state),
    selectedChat: selectedChatSelector(state)
  };
};

const mapDispatchToProps = (dispatch: any): ITopicItemSearchProps => {
  return {
    getTopicPairsByTopicNameWithFilter: (
      topicName: string,
      tags: ISearchingTag[],
      selectedPage: number
    ) =>
      dispatch(
        getTopicPairsByTopicNameWithFilter(
          topicName,
          tags,
          selectedPage
        )
      ),
    getTopicPairCategoriesByTopicName: (topicName: string) =>
      dispatch(getTopicPairCategoriesByTopicName(topicName)),
    changeOperatorChatText: (text: string) =>
      dispatch(changeOperatorChatText(text)),
    changeOperatorMessageContentType: (contentType: TopicPairContentType) => {
        return dispatch(changeOperatorMessageContentType(contentType));
      },
    saveTopicPairUsedTag: (topicPair: ITopicPair, isOperatorTemplate?: boolean) =>
      dispatch(saveTopicPairUsedTag(topicPair, isOperatorTemplate)),
    logOperatorAnswer: (topicPair: ITopicPair, topicName: string | undefined, currentTags: ISearchingTag[] | undefined) =>
      dispatch(logOperatorAnswer(topicPair, topicName, currentTags))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicItemSearch);
