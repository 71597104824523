import {IApiActionResult} from "src/~api/ApiActionResult";
import {IOperator} from "../~store/models/Operator";
import {fetchGet, fetchPost, getApiActionResult} from "src/~api/ApiHelper";
import {
    GET_OPERATORS_API_ROUTE,
    SAVE_OPERATOR_API_ROUTE,
    DELETE_IDENTITY_API_ROUTE,
    DELETE_OPERATOR_API_ROUTE,
    CHANGE_OPERATOR_AVATAR_API_ROUTE,
    GET_SETTING_BY_OPERATOR_ID_API_ROUTE,
    SAVE_OPERATOR_SETTING_API_ROUTE,
    UPDATE_IDENTITY_API_ROUTE,
    DELETE_OPERATOR_SETTING_API_ROUTE,
    GET_OPERATOR_BY_ID_API_ROUTE,
    REGISTER_OPERATOR_API_ROUTE,
    GET_SETTINGS_BY_OPERATORS_ID_API_ROUTE,
    CHECK_IF_USER_EXISTS_API_ROUTE,
} from './apiRoutes';
import { OperatorRole } from "../~store/models/enums/OperatorRole";
import { ISetting } from "src/app/setting/~store/models/setting";
import { IBot } from "../~store/models/Bot";

export const getAllOperatorsAsync = async (): Promise<IApiActionResult<IOperator[]>> => {
    const fetchPromise = fetchGet(GET_OPERATORS_API_ROUTE);

    return await getApiActionResult(fetchPromise, true);
}

export const getOperatorByIdAsync = async (operatorId: number): Promise<IApiActionResult<IOperator>> => {
    const fetchPromise = fetchGet(GET_OPERATOR_BY_ID_API_ROUTE + "?operatorId=" + operatorId);

    return await getApiActionResult(fetchPromise, true);
}

export const saveOperatorAsync = async (newOperator: IOperator, updatedById?: number, scriptFile?: File): Promise<IApiActionResult<IOperator>> => {
    const formData = new FormData();
    console.log(scriptFile);
    formData.append("scriptFile", scriptFile ?? "");
    formData.append("updatedById", updatedById ? updatedById.toString() : "0");
    formData.append("data", JSON.stringify(newOperator));
    const fetchPromise = fetchPost(SAVE_OPERATOR_API_ROUTE ,{
        body: formData
    });
    return await getApiActionResult(fetchPromise, true);;
}

export const checkIfUserExistsAsync = async (userLogin: string): Promise<IApiActionResult<boolean>> => {
    const fetchPromise = fetchGet(CHECK_IF_USER_EXISTS_API_ROUTE + "?userLogin=" + userLogin);

    return await getApiActionResult(fetchPromise, true);
}

export const updateIdentityAsync = async (operatorId: number, login: string, password: string, name: string, role: OperatorRole | undefined, isEnabled: boolean): Promise<IApiActionResult<IOperator>> => {
    const fetchPromise = fetchPost(UPDATE_IDENTITY_API_ROUTE, {
        body: JSON.stringify({
            operatorId,
            login,
            password,
            name,
            role,
            isEnabled
        }),
    });

    return await getApiActionResult(fetchPromise, true);;
}

export const deleteIdentityAsync = async (operator: IOperator): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(DELETE_IDENTITY_API_ROUTE, {
        body: JSON.stringify(
            {
                operatorId: operator.operatorId
            }
        ),
    });

    return await getApiActionResult(fetchPromise);;
}

export const deleteOperatorAsync = async (operator: IOperator, removedById?: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(DELETE_OPERATOR_API_ROUTE, {
        body: JSON.stringify(
            {
                operatorId: operator.operatorId,
                removedById
            }
        ),
    });

    return await getApiActionResult(fetchPromise);;
}

export const changeOperatorAvatarAsync = async (file: File, operator: IOperator): Promise<IApiActionResult<any>> => {
    const result = sendImageAsync(file, operator);
    return await getApiActionResult(result, true);
}

const sendImageAsync = (f: File, operator: IOperator) => {
    return new Promise<any>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const fetchPromise: Promise<any> = fetchPost(CHANGE_OPERATOR_AVATAR_API_ROUTE, 
                {
                    body: JSON.stringify({
                        operatorId: operator.operatorId as number,
                        data: {
                            image: Array.from(new Uint8Array(e.target?.result as ArrayBuffer)),
                        }
                    })  
            })
            resolve(fetchPromise);
        }
        reader.onerror = reject;
        reader.readAsArrayBuffer(f);
    })
}

export const getSettingByOperatorIdAsync = async (operatorId: number): Promise<IApiActionResult<ISetting>> => {
    const fetchPromise = fetchGet(GET_SETTING_BY_OPERATOR_ID_API_ROUTE + operatorId);
    return await getApiActionResult(fetchPromise, true);
}

export const getSettingsByOperatorsIdAsync = async (operatorsId: number[]): Promise<IApiActionResult<ISetting[]>> => {
    const fetchPromise = fetchPost(GET_SETTINGS_BY_OPERATORS_ID_API_ROUTE, {
        body: JSON.stringify({operatorsId})
        });
    return await getApiActionResult(fetchPromise, true);
}

export const saveOperatorSettingAsync = async (
    operatorId: number, 
    setting: string
): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(SAVE_OPERATOR_SETTING_API_ROUTE, {
        body: JSON.stringify({
            operatorId,
            data: {
                value: setting,
            }
        })
    })
    return await getApiActionResult(fetchPromise, false);
}

export const deleteOperatorSettingAsync = async (operatorId: number): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(DELETE_OPERATOR_SETTING_API_ROUTE + operatorId, {});
    return await getApiActionResult(fetchPromise, false);
}

export const registerOperatorAsync = async (
    login: string, 
    password: string,
    name: string,
    role: string,
    operatorId: number,
    isEnabled: boolean
): Promise<IApiActionResult<void>> => {
    const fetchPromise = fetchPost(REGISTER_OPERATOR_API_ROUTE, {
        body: JSON.stringify({
            login,
            password,
            name,
            role,
            operatorId,
            isEnabled
        })
    });
    return await getApiActionResult(fetchPromise);
}
