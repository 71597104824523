import { AppThunkAction } from "src/~store/models/AppThunkAction";
import { UpdateCreatingSettingAction, ChangeSettingSearchStringAction, ChangeSettingSearchTypeAction, DeleteSettingAction, GetAllSettings, SaveSettingAction } from "./interfaces";
import { CHANGE_SETTING_SEARCH_STRING, CHANGE_SETTING_SEARCH_TYPE, DELETE_SETTING_FROM_STATE, GET_ALL_SETTINGS_START, GET_ALL_SETTINGS_SUCCESS, UPDATE_CREATING_SETTING_IN_STATE, UPDATE_SETTING_IN_STATE } from "./types";
import {deleteSettingsAsync, getAllSettingsAsync, saveSettingAsync} from "./../../~api/actions"
import { ISetting, SettingType } from "../models/setting";
import { initNewSetting } from "../state/InitSettingsState";
import { showErrorToastr, showSuccessToastr } from "src/app/shared/helpers/toastr-helper/ToastrHelper";
import i18n from "src/app/shared/localization/i18n";

export const getAllSettings = (): AppThunkAction<GetAllSettings> => async dispatch => {
    dispatch({type: GET_ALL_SETTINGS_START});

    const result = await getAllSettingsAsync();

    if(result.isSuccess) {
        const settings = result.value ?? [];
        settings.forEach(s => s.key = Math.random().toString());
        dispatch({type: GET_ALL_SETTINGS_SUCCESS, payload: { settings }});
    }
}

export const saveSetting = (setting: ISetting): AppThunkAction<SaveSettingAction> => async (dispatch, getState) => {
    if(setting.id.length === 0) {
        showErrorToastr(i18n.t('settings.settingIdCannotBeEmpty'));
        return;
    }
    const {settings} = getState().settingState.settingList;
    if (settings.findIndex(i => i.id == setting.id && i.key != setting.key) > -1) {
        showErrorToastr(i18n.t('settings.settingIdAlreadyExists').replace('{id}', setting.id.toString()));
        return;
    }
    const result = await saveSettingAsync(setting);

    if(result.isSuccess) {
        setting.isCreating = false;
        showSuccessToastr(i18n.t('settings.settingSaved'));
        dispatch({type: UPDATE_SETTING_IN_STATE, payload: {setting}});
    }
}

export const createSetting = (): AppThunkAction<SaveSettingAction> => async dispatch => {

    await dispatch({type: DELETE_SETTING_FROM_STATE, payload: {id: ""}});

    dispatch({type: UPDATE_SETTING_IN_STATE, payload: {setting: initNewSetting(SettingType.Integration)}});
}

export const updateCreatingSetting = (setting: ISetting | undefined): AppThunkAction<UpdateCreatingSettingAction> => async dispatch => {
    dispatch({type: UPDATE_CREATING_SETTING_IN_STATE, payload: {setting}});
}

export const deleteSettingById = (id: string): AppThunkAction<DeleteSettingAction> => async dispatch => {
    if(id.length > 0) {
        const result = await deleteSettingsAsync(id);
        if (result.isSuccess) {
            showSuccessToastr(i18n.t('settings.settingDeleted'));
            dispatch({type: DELETE_SETTING_FROM_STATE, payload: {id}});
        }
    }
    dispatch({type: DELETE_SETTING_FROM_STATE, payload: {id}});
}

export const changeSearchString = (value: string): AppThunkAction<ChangeSettingSearchStringAction> => async dispatch => {
    dispatch({type: CHANGE_SETTING_SEARCH_STRING, payload: {value}});
}

export const changeSearchType = (type: SettingType | undefined): AppThunkAction<ChangeSettingSearchTypeAction> => async dispatch => {
    dispatch({type: CHANGE_SETTING_SEARCH_TYPE, payload: {type}});
}