const HOST_API_ROUTE = `${process.env.REACT_APP_CHAT_API_ROUTE}`;
const KB_API_ROUTE = `${process.env.REACT_APP_KB_API_ROUTE}`;

const CHAT_API_ROUTE = `${HOST_API_ROUTE}/chat`;
const MESSAGE_API_ROUTE = `${HOST_API_ROUTE}/message`;
const OPERATOR_API_ROUTE = `${HOST_API_ROUTE}/operator`;
const CHANNEL_API_ROUTE = `${HOST_API_ROUTE}/channel`;
const CONTACT_API_ROUTE = `${HOST_API_ROUTE}/contact`;
const PICTURE_API_ROUTE = `${HOST_API_ROUTE}/picture`;
const FILE_API_ROUTE = `${HOST_API_ROUTE}/file`;
const SETTING_API_ROUTE = `${HOST_API_ROUTE}/setting`;
const ANSWER_API_ROUTE = `${KB_API_ROUTE}/answer`;

export const GET_OPENED_OPERATOR_CHATS_API_ROUTE = `${CHAT_API_ROUTE}/get-opened-operator-chats`;

export const GET_CHAT_MESSAGES_API_ROUTE = `${MESSAGE_API_ROUTE}/get-messages-by-chat-id`;

export const SEND_CHAT_MESSAGE_API_ROUTE = `${MESSAGE_API_ROUTE}/operator-send-message`;

export const RESEND_CHAT_MESSAGE_API_ROUTE = `${MESSAGE_API_ROUTE}/operator-resend-message`;

export const OPERATOR_SEND_FILE_MESSAGE_API_ROUTE = `${MESSAGE_API_ROUTE}/operator-send-file-message`;

export const ASSIGNMENT_CHATS_TO_OPERATOR_API_ROUTE = `${CHAT_API_ROUTE}/assignment-chats-to-operator`;

export const GET_OPERATOR_API_ROUTE = `${OPERATOR_API_ROUTE}/get-operator`;

export const GET_OPERATOR_CHANNELS_API_ROUTE = `${OPERATOR_API_ROUTE}/get-current-operator-channels`;

export const GET_OPERATORS_STATUS_LIST_API_ROUTE = `${OPERATOR_API_ROUTE}/get-operators-status-list`;

export const GET_AVAILABLE_CONTACT_TAGS_API_ROUTE = `${CONTACT_API_ROUTE}/get-available-contact-tags`;

export const GET_SETTINGS_BY_ID_API_ROUTE = `${SETTING_API_ROUTE}/get-setting-by-id`; 

export const GET_SETTING_BY_CHANNEL_ID_API_ROUTE = `${CHANNEL_API_ROUTE}/get-setting-by-channel-id`;

export const GET_CONTACT_API_ROUTE = `${CONTACT_API_ROUTE}/get-contact-by-id`;

export const CLOSE_CHAT_BY_ID_API_ROUTE = `${CHAT_API_ROUTE}/close-chat-by-chat-id`;

export const REMOVE_OPERATOR_FROM_CHAT_API_ROUTE = `${CHAT_API_ROUTE}/remove-operator-from-chat`;

export const OPEN_CHAT_TO_OPERATOR_API_ROUTE = `${CHAT_API_ROUTE}/open-chat-by-id`;

export const CONTINUE_CHAT_API_ROUTE = `${CHAT_API_ROUTE}/continue-chat`;

export const GET_OPENED_CONTACT_CHATS_API_ROUTE = `${CHAT_API_ROUTE}/get-opened-chats-by-contact-id?contactId=`;

export const GET_PICTURE_BY_ID_API_ROUTE = `${PICTURE_API_ROUTE}/get-picture-by-id?pictureId=`;

export const GET_FILE_BY_ID_API_ROUTE = `${FILE_API_ROUTE}/get-file-by-id?fileId=`;

export const GET_ONLINE_OPERATORS_API_ROUTE = `${OPERATOR_API_ROUTE}/get-online-operators`;

export const GET_ONLINE_OPERATORS_TO_TRANSFER_API_ROUTE = `${OPERATOR_API_ROUTE}/get-online-operators-to-transfer`;

export const GET_AVAILIBLE_TIERS_API_ROUTE = `${OPERATOR_API_ROUTE}/get-available-tiers`;

export const GET_AVAILIBLE_BOTS_TRANSFER_TO_API_ROUTE = `${OPERATOR_API_ROUTE}/get-available-bots-transfer-to`;

export const GET_ONLINE_OPERATORS_TO_INVITE_API_ROUTE = `${OPERATOR_API_ROUTE}/get-online-operators-to-invite`;

export const GET_OPENED_CHATS_BY_OPERATOR_ID_API_ROUTE = `${CHAT_API_ROUTE}/get-opened-chats-by-operator-id?operatorId=`;

export const TRANSFER_CHAT_API_ROUTE = `${CHAT_API_ROUTE}/transfer-chat?redirectedBy=`;

export const MARK_CHAT_AS_SPAM = `${CHAT_API_ROUTE}/mark-chat-as-spam`;
export const IS_CHAT_MARKED_AS_SPAM = `${CHAT_API_ROUTE}/is-chat-spam?chatId=`;

export const TRANSFER_CHAT_TO_TIER_API_ROUTE = `${CHAT_API_ROUTE}/transfer-chat-to-tier`;

export const INVITE_OPERATORS_TO_CHAT_API_ROUTE = `${OPERATOR_API_ROUTE}/invite-operators-to-chat`;

export const EDIT_MESSAGE_API_ROUTE = `${MESSAGE_API_ROUTE}/edit-message`;

export const JOIN_CHAT_API_ROUTE = `${CHAT_API_ROUTE}/join-chat?chatId=`;
export const PAUSE_CHAT_API_ROUTE = `${CHAT_API_ROUTE}/pause-chat?chatId=`;
export const UNPAUSE_CHAT_API_ROUTE = `${CHAT_API_ROUTE}/unpause-chat?chatId=`;

export const GET_ANSWER_FROM_HISTORY_CHAT_API_ROUTE = `${ANSWER_API_ROUTE}/answers-from-history?answerText=`;

export const REMOVE_ANSWER_FROM_HISTORY_CHAT_API_ROUTE = `${ANSWER_API_ROUTE}/remove-answer-from-history`;

export const EXPORT_CHAT_TO_EXTERNAL_CRM_API_ROUTE = `${CHAT_API_ROUTE}/export-chat-to-external-crm?chatId=`;

export const GET_CHANNEL_OPERATORS_API_ROUTE = `${CHANNEL_API_ROUTE}/get-channel-operators?`;

export const ADD_CONTACT_TAGS_API_ROUTE = `${CONTACT_API_ROUTE}/add-contact-tags`;
export const DELETE_CONTACT_TAGS_API_ROUTE = `${CONTACT_API_ROUTE}/delete-contact-tags`;
export const GET_IS_CONTACT_ONLINE_API_ROUTE = `${CONTACT_API_ROUTE}/get-is-contact-online`;