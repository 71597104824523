import * as React from "react";
import { useEffect } from "react";
import { IMessageItem, MessageType } from "../~store/models/MessageItem";

import './MessageItem.css';
import { botTextToComponentWithLinks, clientMessageTextToComponentWithLinks } from "src/app/shared/helpers/weblinks-helper/WeblinksHelper";
import { GET_FILE_BY_ID_API_ROUTE, GET_PICTURE_BY_ID_API_ROUTE } from "../~api/apiRoutes";
import AuthorName from "./AuthorName/AuthorName";
import { IFileItem } from "../~store/models/FileItem";
import { getTime, getDateTime } from "src/app/shared/helpers/date-helper/DateFormats";
import { OperatorType } from "../~store/models/OperatorItem";
import history from "src/~store/history";
import RetryButton from '@mui/icons-material/ReplaySharp';
import { TopicPairContentType } from "src/app/nlp/~store/models/enums/TopicPairContentType";
import { ConvertTextToContent } from "src/app/shared/components/text-to-content/TextToContent";
import { IChatItem } from "../~store/models/ChatItem";
import { useTranslation } from 'react-i18next';

interface IMessageProps {
    messageItem: IMessageItem,
    authorName: string | null,
    operatorId: number,
    chatOwnerId: number,
    isSupervisor: boolean,
    nlpTopicName: string,
    onRemoveOperator: (operatorId: number, chatId: number) => void,
    onMessageDidMount: () => void,
    resendMessage: (messageId: number) => void,
    selectedChat: IChatItem | undefined
}

const MessageItem = (props: IMessageProps) => {
    const { t } = useTranslation();
    useEffect(() => {
        props.onMessageDidMount();
      }, []);

    let mt = "";
    const userInStorage = sessionStorage.getItem("user");
    const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;

    switch (props.messageItem.messageType) {
        case MessageType.operatorMessage:
        case MessageType.clientSystemMessage:
            mt = "operator-message-type";
            break;
        case MessageType.clientMessage:
            mt = "client-message-type";
            break;
        case MessageType.chatInfoMessage:
            mt = "chat-info-messageType"
            break;
    }

    const onRemoveOperatorHandler = () => {
        const { operatorId, chatId } = props.messageItem;
        props.onRemoveOperator(operatorId ?? 0, chatId);
    }

    const mapFileToComponent = (file: IFileItem, contentType: TopicPairContentType) => {
        if (file == null || file.fileId === null) return;
        switch (contentType) {
            case TopicPairContentType.Video:
                return ConvertTextToContent(contentType, GET_FILE_BY_ID_API_ROUTE + file.fileId + "&customerId=" + customerId, props.messageItem.operatorModel?.type === OperatorType.Bot);
            case TopicPairContentType.Audio:
                return ConvertTextToContent(contentType, GET_FILE_BY_ID_API_ROUTE + file.fileId + "&customerId=" + customerId, props.messageItem.operatorModel?.type === OperatorType.Bot);
            default: return (file.mimeType === "video/mp4" ?
                    <video
                    className="message-item__media"
                    src={GET_FILE_BY_ID_API_ROUTE + file.fileId + "&customerId=" + customerId}
                    autoPlay={false}
                    loop={false}
                    preload="none"
                    controls={true}
                    onClick={(e) => {
                        e.preventDefault();
                        const target = e.target as HTMLVideoElement;
                        if (target && target.tagName === "VIDEO") {
                            if (target.paused) {
                                target.play();
                            } else {
                                target.pause();
                            }
                        }
                    }}
                /> :
                <div className="message-item__file_link">
                    <a href={GET_FILE_BY_ID_API_ROUTE + file.fileId + "&customerId=" + customerId}>{file.name}</a>
                </div>
            )
        }
    }

    const retryButtonComponent = () => {
        if (props.messageItem.isFailed && props.messageItem.operatorId === props.operatorId && !(props.selectedChat?.isClosed ?? false) && props.selectedChat?.chatId === props.messageItem.chatId)
        return (
            <RetryButton titleAccess={t('chat.resendMessage')} className="retry-send-button" color={ "error" } onClick={() => props.resendMessage(props.messageItem.messageId)}></RetryButton>
        );
    }

    

    let mapUrl = "https://maps.google.com/maps?q=" + props.messageItem.location?.latitude + "," + props.messageItem.location?.longitude + "&z=15&output=embed";


    return (
        <div>
            {props.messageItem.messageType === MessageType.previewMessage && <div>text</div>}
            {props.messageItem.messageType !== MessageType.chatInfoMessage ? (props.messageItem.messageType !== MessageType.systemMessage ? 
                <div className={"message-item " + mt + (props.messageItem.isFailed ? " failed-message" : "")}>
                    {props.authorName !== null && 
                        <AuthorName 
                            authorName={props.authorName} 
                            messageTypeClass={mt}
                            messageType={props.messageItem.messageType}
                            onRemoveOperator={onRemoveOperatorHandler}
                            operatorId={props.operatorId}
                            messageOperatorId={props.messageItem.operatorId ?? 0}
                            chatOwnerId={props.chatOwnerId}
                            isSupervisor={props.isSupervisor} />
                    }
                    {retryButtonComponent()}
                    {props.messageItem.imageId !== null &&
                        <div className="btn-rotate-img">
                        <div className="rotate--image">
                            <a href={GET_PICTURE_BY_ID_API_ROUTE + props.messageItem.imageId +"&customerId=" + customerId} target="_blank">
                            <img className="message-item__media" src={GET_PICTURE_BY_ID_API_ROUTE + props.messageItem.imageId +"&customerId=" + customerId} />
                            </a>
                        </div>
                        </div>}                    
                    {props.messageItem.location !== null &&
                    <iframe className="location_iframe" src={mapUrl} loading="lazy"></iframe> ||
                    <div className="message-text">{ConvertTextToContent(props.messageItem.file ? TopicPairContentType.Text : props.messageItem.contentType,  props.messageItem.text, props.messageItem.operatorModel?.type === OperatorType.Bot)}</div>}
                    {mapFileToComponent(props.messageItem.file, props.messageItem.contentType)}
                    {props.messageItem.messageType === MessageType.clientMessage && props.nlpTopicName.length > 0 &&
                        <div title="Search in NLP..." className="message-item__new-kb" onClick={() => {
                            history.push(`/nlp/search/${props.nlpTopicName}`, {theme: props.messageItem.text, time: new Date().getTime()});
                        }}>?</div>
                    }
                    <div className="message-time">{getTime(new Date (props.messageItem.timeSent))}</div>
                </div> :
                <div className="system-message-item">
                    <span>{getDateTime(new Date(props.messageItem.timeSent)) + " "}</span>
                    <span>{props.messageItem.text}</span>
                </div>) :
                <div className={"message-item " + mt}>{"Chat " + props.messageItem.text} </div>
            }
        </div>
    );
    
}

export default MessageItem;