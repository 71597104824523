import { Action, Reducer } from "redux";
import { IAnalyticsState } from "./state/AnalyticsState";
import { initAnalysticsState } from './state/InitAnaliticsState';
import { 
    ChangeAnalyticTabAction,
    ChangeAnalyticSubtabAction,
    ChangeSelectedBotSuccessAction, 
    GetBotsSuccessAction, 
    GetChatStepsSuccessAction, 
    GetSettingByIdSuccessAction,
    UpdateChartsByFilter,
    GetReportsSettingByIdActionSuccess,
    ChangeReportsSubtabActionSuccess,
    GetReportByNameActionSuccess,
    SetAnalyticsTagsAction
} from "./actions/interfaces";

import { 
    CHANGE_ANALYTIC_TAB,
    CHANGE_ANALYTIC_SUBTAB,
    CHANGE_SELECTED_BOT_SUCCESS, 
    GET_BOTS_SUCCESS, 
    GET_CHAT_STEPS_SUCCESS, 
    GET_SETTING_BY_ID_SUCCESS, 
    UPDATE_CHARTS_BY_FILTER,
    GET_REPORTS_SETTING_BY_ID_SUCCESS,
    CHANGE_REPORT_SUBTAB_SUCCESS,
    GET_REPORT_BY_NAME_SUCCESS,
    SET_ANALYTICS_TAGS_SUCCESS,
    SAVE_REPORT_BY_NAME_START,
    SAVE_REPORT_BY_NAME_SUCCESS,
    SAVE_REPORT_BY_NAME_ERROR
} from "./actions/types";
import { removeQueryStringParameter, setOrUpdateQueryStringParameter } from "src/app/shared/helpers/weblinks-helper/WeblinksHelper";
import { ConvertFromFilterToTimeInterval } from "src/app/shared/helpers/date-helper/FromFilterToDateHelper";

const filterCharts = (date: string | undefined, channelId: string | undefined, allCharts: string[]): string[] => {
    
    let userInStorage = sessionStorage.getItem("user");
    let customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;

    let parser = new DOMParser();
    const newNodes: HTMLElement[] = [];
    for(var key in allCharts) {
        let htmlElement = parser.parseFromString(allCharts[key], 'text/html');
        newNodes.push(htmlElement.body)
    }
    const newCharts: string[] = [];
    const filterResult = date !== undefined ? ConvertFromFilterToTimeInterval(date) : undefined;
    for(let node of newNodes) {
        let element = node.getElementsByTagName("iframe")[0] as HTMLElement;
        let elementSrc: string = "";
        if(typeof(element.getAttribute) !== typeof(undefined)) {
            elementSrc = element.getAttribute("src") ?? "";
        } else {
            continue;
        }

        if (filterResult !== undefined) {
            elementSrc = removeQueryStringParameter(elementSrc, "from");
            elementSrc = setOrUpdateQueryStringParameter(elementSrc, "from", filterResult.from);
            if(filterResult.to === undefined) {
                elementSrc = removeQueryStringParameter(elementSrc, "to");
            } else {
                elementSrc = removeQueryStringParameter(elementSrc, "to");
                elementSrc = setOrUpdateQueryStringParameter(elementSrc, "to", filterResult.to);
            }
        }
        
        if (channelId !== undefined) {
            elementSrc = removeQueryStringParameter(elementSrc, "var-ChannelId")
            elementSrc = setOrUpdateQueryStringParameter(elementSrc, "var-ChannelId", channelId);
        }

        if(customerId !== undefined) {
            elementSrc = removeQueryStringParameter(elementSrc, "var-CustomerId")
            elementSrc = setOrUpdateQueryStringParameter(elementSrc, "var-CustomerId", customerId);
        }

        element.setAttribute("src", elementSrc);
        newCharts.push(element.outerHTML);
    }

    return newCharts;
}

export type KnownActions = 
    | GetChatStepsSuccessAction
    | GetBotsSuccessAction
    | ChangeSelectedBotSuccessAction
    | GetSettingByIdSuccessAction
    | UpdateChartsByFilter
    | ChangeAnalyticTabAction
    | ChangeAnalyticSubtabAction
    | GetReportByNameActionSuccess;

export const analyticsReducer: Reducer<IAnalyticsState> = (
    state: IAnalyticsState = initAnalysticsState,
    incommingAction: Action
): IAnalyticsState => {
    const action = incommingAction as KnownActions;
    switch(action.type) {

        case GET_CHAT_STEPS_SUCCESS: {
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    customerJourney: {
                        ...state.analytics.customerJourney,
                        chatSteps: (action as GetChatStepsSuccessAction).payload.chatSteps
                    }
                }
            }
        }

        case GET_BOTS_SUCCESS: {
            const bots = (action as GetBotsSuccessAction).payload.bots;
            const selectedBotId = bots.length > 0 ? bots[0].operatorId : state.analytics.customerJourney.selectedBotId;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    customerJourney: {
                        ...state.analytics.customerJourney,
                        bots,
                        selectedBotId
                    }
                }
            }
        }

        case CHANGE_SELECTED_BOT_SUCCESS: {
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    customerJourney: {
                        ...state.analytics.customerJourney,
                        selectedBotId: (action as ChangeSelectedBotSuccessAction).payload.selectedBotId
                    }
                }
            }
        }

        case GET_SETTING_BY_ID_SUCCESS: {
            const setting = (action as GetSettingByIdSuccessAction).payload.setting;
            const selectedSubtab = (action as GetSettingByIdSuccessAction).payload.selectedSubtab;
            const tags = (action as GetSettingByIdSuccessAction).payload.tags;
            const defaultSubtab: string = "Default";
            const allKeys: string[] = [];
            var allCharts: string[] = [];
            let currentSubtab: string = "";
            if (setting.value?.length > 0) {
                const chartsSettings = JSON.parse(setting.value);
                for(var key in chartsSettings) {
                    let subtab = key.split(".");
                    if (subtab.length === 2 && !subtab[0].match(/^ *$/) && subtab[0] !== null) {
                        if (currentSubtab === "") currentSubtab = ((selectedSubtab === defaultSubtab && Object.keys(chartsSettings).find(key => key.split(".").length === 1)) || Object.keys(chartsSettings).find(key => key.split(".")[0] === selectedSubtab)) ? selectedSubtab : subtab[0];
                        if (!allKeys.includes(subtab[0])) allKeys.push(subtab[0]);
                        if (subtab[0] === currentSubtab) allCharts.push(chartsSettings[key]);
                    }
                    else if (subtab.length === 1 || subtab[0].match(/^ *$/) || subtab[0] === null) {
                        if (currentSubtab === "") currentSubtab = Object.keys(chartsSettings).find(key => key.split(".")[0] === selectedSubtab) ? selectedSubtab : defaultSubtab;
                        if (!allKeys.includes(defaultSubtab)) allKeys.push(defaultSubtab);
                        if (currentSubtab === defaultSubtab) allCharts.push(chartsSettings[key]);
                    }
                }
            }        

            if (tags.length > 0) {
                allCharts = filterCharts(state.analytics.date, undefined, allCharts);
            }
            else {
                allCharts = filterCharts(undefined, undefined, allCharts);
            }

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    charts: {
                        allCharts: [...allCharts]
                    },
                    currentSettings: setting.value,
                    allKeys: [...allKeys],
                    date: state.analytics.date,
                    selectedSubtab: currentSubtab,
                    tags: tags
                }
            }
        }

        case UPDATE_CHARTS_BY_FILTER: {
            const {date, channelId, nodes} = (action as UpdateChartsByFilter).payload;    

	        const newCharts = filterCharts(date, channelId, state.analytics.charts.allCharts);

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    charts: {
                        allCharts: [...newCharts]
                    },
                    date: date,
                    channelId: channelId,
                    tags: state.analytics.tags
                }
            }
        }

        case CHANGE_ANALYTIC_TAB: {
            const { tab } = (action as ChangeAnalyticTabAction).payload;
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    selectedTab: tab,
                    charts: {
                        allCharts: []
                    },
                    date: state.analytics.date,
                    tags: state.analytics.tags
                }
            }
        } 

        case CHANGE_ANALYTIC_SUBTAB: {
            const { tab, subtab, currentSettings, tags } = (action as ChangeAnalyticSubtabAction).payload;
            const chartsSettings = JSON.parse(currentSettings);
            var allCharts: string[] = [];
            for(var key in chartsSettings) {
                let currentSubtabs = key.split(".");
                if (currentSubtabs.length === 2 && currentSubtabs[0] === subtab) {
                    allCharts.push(chartsSettings[key]);
                }
                else if (currentSubtabs.length === 1 && subtab === "Default") {
                    allCharts.push(chartsSettings[key]);
                }
            }
            if (tags.length > 0) {
                allCharts = filterCharts(state.analytics.date, state.analytics.channelId, allCharts);
            } 

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    charts: {
                        allCharts: [...allCharts]
                    },
                    allKeys: [...state.analytics.allKeys],
                    selectedSubtab: subtab,
                    date: state.analytics.date,
                    channelId: state.analytics.channelId,
                    tags: tags
                }
            }
        }

        case SET_ANALYTICS_TAGS_SUCCESS: {
            const { tags } = (action as SetAnalyticsTagsAction).payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    tags
                }
            }
        }

        case GET_REPORTS_SETTING_BY_ID_SUCCESS: {
            const { setting } = (action as GetReportsSettingByIdActionSuccess).payload;

            const settingValue = JSON.parse(setting.value);
            const allKeys = Object.keys(settingValue);

            let selectedSubtab = state.analytics.reports.selectedSubtab;

            if(!allKeys.includes(state.analytics.reports.selectedSubtab)) {
                if(allKeys.length > 0) {
                    selectedSubtab = allKeys[0];
                }
                else {
                    selectedSubtab = '';
                }
            }

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    reports: {
                        ...state.analytics.reports,
                        allKeys,
                        selectedSubtab,
                        currentSettings: setting.value,
                    }
                }
            }
        }

        case CHANGE_REPORT_SUBTAB_SUCCESS: {
            const { subtab } = (action as ChangeReportsSubtabActionSuccess).payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    reports: {
                        ...state.analytics.reports,
                        selectedSubtab: subtab
                    }
                }
            }
        }

        case GET_REPORT_BY_NAME_SUCCESS: {
            const { report } = (action as GetReportByNameActionSuccess).payload;

            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    reports: {
                        ...state.analytics.reports,
                        report
                    }
                }
            }
        }

        case SAVE_REPORT_BY_NAME_START: {
            return {
                ...state, analytics: { ...state.analytics, reports: { ...state.analytics.reports, isLoad: true } }
            }
        }

        case SAVE_REPORT_BY_NAME_SUCCESS:
        case SAVE_REPORT_BY_NAME_ERROR: {

            return {
                ...state, analytics: { ...state.analytics, reports: { ...state.analytics.reports, isLoad: false } }
            }
        }

        default:
            return state;
    }
}