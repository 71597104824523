import { IChatItem } from "../models/ChatItem";
import { IMessagePreview } from "../models/MessagePreview";
import { IMessageItem, ISendingMessage } from "../models/MessageItem";
import { IChannelItem, OperatorPriorityType } from "../models/ChannelItem";
import { IOperatorItem } from "../models/OperatorItem";
import { IQuickAnswer } from "../models/QuickAnswer";
import { HubConnection } from "@microsoft/signalr";
import { ISetting } from "src/app/setting/~store/models/setting";
import { TopicPairContentType } from "src/app/nlp/~store/models/enums/TopicPairContentType";

export interface IChatItemsState {
    chatItems: IChatItem[],
    chatMessagePreview: IMessagePreview[],
    silentChatsId: number[],
    selectedChat: IChatItem | undefined,
    canSendMessage: boolean,
    isChatVisible: boolean,
    isReceivingChats: boolean,
    operatorStatus: OperatorStatusType,
    pauseCategories: string[],
    showHintsFromAllOperators: boolean,
    closeChatCategories: string[],
    closeChatSubjects: string[],
    customChatTagsSetting: ISetting | undefined,
    isGettingCloseChatSubjects: boolean,
    reconnectingStatus: boolean,
    hubConnection: HubConnection,
    selectedChatId: number
    messages: IMessageItem[],
    sendingMessage: ISendingMessage | null,
    operatorCurrentChatText: string,
    operatorCurrentMessageContentType: TopicPairContentType,
    operatorName: string,
    operatorAvatarId: string | null,
    operatorId: number,
    contactName: string,
    isContactOnline: boolean | undefined,
    contactAvatarId: string | null,
    contactTags: string[],
    channelSettings: ISetting | undefined,
    operatorChannels: IChannelItem[],
    isReceivingOnlineOperators: boolean,
    onlineOperators: IOperatorItem[],
    isReceivingOnlineOperatorsToInvite: boolean,
    isReceivingAvailableTiers: boolean,
    availableTiers: OperatorPriorityType[],
    availableContactTags: string[],
    updatingContactTags: boolean,
    onlineOperatorsToInvite: IOperatorItem[],
    botsTransferTo: IOperatorItem[],
    isMessageEditing: boolean,
    messageToEdit: IMessageItem,
    chatHistory: IChatHistoryContainer,
    quickAnswersContainer: IQuickAnswerContainer,
    chatsDrafts: Map<number, string>,
    operatorsStatusList: IOperatorStatusViewModel[],
    isChatClosing: boolean,
    operatorDisconnectionTimeout: number,
    sendingFiles: ISendingFileContainer[],
    isReceivingChat: boolean,
    isSendingMessage: boolean
}

export enum OperatorStatusType {
    Online = 1,
    Pause = 2,
    Offline = 3,
    Pause1 = 401,
    Pause2 = 402,
    Pause3 = 403,
    Pause4 = 404,
    Pause5 = 405,
    Pause6 = 406,
    Pause7 = 407,
    Pause8 = 408,
    Pause9 = 409,
    Pause10 = 410
}

export interface IChatHistoryContainer {
    canGetMoreBefore: boolean,
    canGetMoreAfter: boolean,
    gotBefore: number,
    gotAfter: number,
    isReceivingHistory: boolean
}

export interface IQuickAnswerContainer {
    quickAnswers: IQuickAnswer[],
    isReceivingQuickAnswers: boolean
}
 
export interface IOperatorStatusViewModel {
    operatorId: number,
    name: string,
    status: OperatorStatusType,
    setOn: Date
}

export interface ISendingFileContainer {
    sendingFilesCount: number,
    selectedChatId: number
}