import * as React from "react";
import MDEditor from "@uiw/react-md-editor";
import { fetchGetFile, getApiActionResult } from "src/~api/ApiHelper";
import './WeblinksHelper.css';
import { showErrorToastr } from "../toastr-helper/ToastrHelper";
import MarkupErrorBoundary from "../error-boundary/MarkupErrorBoundary";

export const botTextToComponentWithLinks = (text: string) => {
    if (!text) { return };
    if (isAllCharactersSame(text)) { return text };
    return <MarkupErrorBoundary text={text}>
                <MDEditor.Markdown source={text} linkTarget="_blank" />
            </MarkupErrorBoundary>;
}

export const clientMessageTextToComponentWithLinks = (text: string) => {
    if (!text) { return };
    const lines = text.split(/\n/);

    const onLinkClick = async (url: string) => {
        if(url.indexOf('/get-uniform-file?') > -1) { //for uniform file download
            const urlParams = new URLSearchParams(url);

            const fetchPromise = fetchGetFile(url, urlParams.get('filename') ?? "file");
            var result = await getApiActionResult(fetchPromise, false);
            if (!result.isSuccess) {
                showErrorToastr("Ошибка загрузки файла");
            }
        }
        else {
            window.open(url, '_blank', 'noreferrer')
        }
    }

    const allLine = lines.map((line, j) => {
        const tokens = line.split(/\s/);
        const contents = tokens.map((token, i) => {
            const hasSpace = i !== (tokens.length - 1);
            const maybeSpace = hasSpace ? ' ' : '';

            if (token.match(/(\b(https?|ftp|file|viber):\/\/[\p{L}0-9\-+&@#\/%?=~_|!:,.;]*[\p{L}0-9+&@#\/%=~_|])/iug)) {
                const url = token.match(/(\b(https?|ftp|file|viber):\/\/[\p{L}0-9\-+&@#\/%?=~_|!:,.;]*[\p{L}0-9+&@#\/%=~_|])/iug);
                return (
                    <>
                        {url ? token.split(url[0])[0] : ""}
                        <button className="button-link"
                            key={i}
                            onClick={() => {onLinkClick(url ? url[0] : "")}}
                            >
                            {url ? url[0] : ""}
                        </button>
                        {url ? token.split(url[0])[1] : ""}
                        {maybeSpace}
                    </>
                );
            } else {
                return token + maybeSpace;
            }
        })
        return (
            line === '' ? <br></br> : <div key={j} style={{ textAlign: "left" }}>{contents}</div>
        )
    })

    return <div>{allLine}</div>;
}


export const setOrUpdateQueryStringParameter = (uri: string, key: string, value: string) => {
    var i = uri.indexOf('#');
    var hash = i === -1 ? ''  : uri.substr(i);
         uri = i === -1 ? uri : uri.substr(0, i);

    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
}

export const removeQueryStringParameter = (uri: string, key: string) => {
    var i = uri.indexOf('#');
    var hash = i === -1 ? ''  : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);

    uri = uri.replace(new RegExp(key + "=.*?(&|$)", "i"), '');
    if (uri.slice(-1) === '?') {
        uri = uri.slice(0, -1);
    }

    return uri + hash;
}

const isAllCharactersSame = (str: string) => {
    for (let i = 1; i < str.length; i++) {
        if (str[i] !== str[0]) return false;
    }
    return true;
}
