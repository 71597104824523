import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { getChartsSettingById, updateChartsDate } from 'src/app/analytics/~store/actions/actions';
import { allChartsSelector, selectedSubtabSelector } from 'src/app/analytics/~store/selector';
import { IApplicationState } from 'src/~store/models/ApplicationState';
import { IChartsExternalProps, IChartsProps } from './ChartsProps';

import "./Charts.css";
import { ISearchingTag, TagName, TagType } from 'src/app/shared/components/tag-searching-input/~types/models/SearchingTag';

type ReduxType = ReturnType<typeof mapStateToProps> & IChartsProps;

const Charts = (props: ReduxType) => {
    const chartsContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        _removeAllChartsFromDocument(chartsContainerRef);
        props.getChartsSettingById(props.settingId, props.selectedSubtab, props.tags);
    }, [props.settingId])

    useEffect(() => {
        _updateCharts(props.allCharts);
    }, [props.allCharts]);

    useEffect(() => {
        _updateChartsDate();
    }, [props.tags]);
    
    const _updateChartsDate = () => {
        if (props.tags.length === 0 && props.allCharts?.length > 0) {
            props.getChartsSettingById(props.settingId, props.selectedSubtab, props.tags);
        } else {
            if(chartsContainerRef.current !== undefined && chartsContainerRef.current?.childNodes !== undefined) {
                let dateTagIndex = props.tags.findIndex(el => {return el.tagType === TagType.ClosingTag && el.tagName === TagName.Date});
                let channelTagIndex = props.tags.findIndex(el => {return el.tagType === TagType.ClosingTag && el.tagName === TagName.ChannelName});
                
                props.updateChartsDate(props.tags[dateTagIndex]?.value, props.tags[channelTagIndex]?.externalValue ?? "0", chartsContainerRef.current?.childNodes);
            }
        }
    }

    const _updateCharts = (allCharts: string[]) => {
        _removeAllChartsFromDocument(chartsContainerRef);
        for(var chart of allCharts) {
            chartsContainerRef.current?.insertAdjacentHTML('beforeend', chart);
        }
    }

    const _removeAllChartsFromDocument = (ref: React.RefObject<HTMLDivElement>) => {
        let countOfNodes = ref.current?.childNodes?.length ?? 0;
        for(let i = 0; i < countOfNodes; i++) {
            const node = ref.current?.childNodes[0];
            ref.current?.removeChild(node ?? {} as Node);
        }
    }

    return(
        <div className="charts-continer" ref={chartsContainerRef}>
        </div>
    )
}

const mapStateToProps = (state: IApplicationState, externalProps: IChartsExternalProps) => {
    return {
        settingId: externalProps.settingId,
        tags: externalProps.tags,
        allCharts: allChartsSelector(state),
        selectedSubtab: selectedSubtabSelector(state)
    };
}

const mapDispatchToProps = (dispatch: any): IChartsProps => {
    return {
        getChartsSettingById: (settingId: string, selectedSubtab: string, tags: ISearchingTag[]) => dispatch(getChartsSettingById(settingId, selectedSubtab, tags)),
        updateChartsDate: (date: string, channelId: string, nodes: NodeListOf<ChildNode>) => dispatch(updateChartsDate(date, channelId, nodes)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Charts);