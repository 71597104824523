import {TopicPairStatus} from "../models/enums/TopicPairStatus";
import { ITopic } from "../models/Topic";
import {IKnowledgeBaseState} from "./KnowledgeBaseState";
import { SortingOption } from "../models/enums/SortingOption"

export const initTopic = (): ITopic => {
    return {
        name: "",
        topicId: 0,
        topicPairsCount: 0,
        topicExternalId: "",
        parametersJson: ""
    }
}

export const initState: IKnowledgeBaseState = {
    isOperationInProgress: false,
    filterTags: [],
    main: {
        topics: [],
        isCreatingTopic: false,
        isSortingByTopicId: SortingOption.Decrease,
        isSortingByTopicName: SortingOption.None
    },
    topicPairsSearch: {
        selectedTopic: initTopic(),
        topicPairCategories: [],
        topicPairSources: []
    },
    topicEditor: {
        selectedTopic: initTopic(),
        isCreatingTopicPair: false,
        isExportingPairs: false,
        isPublishingQuestions: false,
        isGeneratingQuestions: false,
        selectedTopicPairStatus: TopicPairStatus.Active,
        transferQuestionPopup: {
            popupData: {
                questionId: 0,
                topicPairId: 0,
                topicExternalId: ""
            },
            isTransferQuestionPopupVisible: false
        },
        messageBox: {
            data: {
                title: "",
                message: ""
            },
            isMessageBoxVisible: false
        },
        topicWidget: {
            isTopicWidgetVisible: false,
            isGetAnswerInProgress: false,
            messages: [],
        }
    },
    topicQuestion: {
        questions: []
    }
};
