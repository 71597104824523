﻿import * as React from "react";
import {connect} from "react-redux";

import {MessageBoxType} from "../~store/models/enums/MessageBoxType";
import {ITopicListProps} from "./~types/TopicListProps";
import {IApplicationState} from "src/~store/models/ApplicationState";
import {
    isMessageBoxVisibleSelector,
    isOperationInProgressSelector,
    isSortingByTopicIdSelector,
    isSortingByTopicNameSelector
} from "../~store/selectors";
import {topicsSelector, isCreatingTopicSelector} from "../~store/selectors";
import {getUserExternalIdFromUrl, buildUrlWithUserSegment} from "src/app/shared/helpers/user-auth-helper/UserAuthHelper";
import {
    createTopic, 
    cancelCreateTopic, 
    saveTopic, 
    deleteTopic, 
    setMessageBoxData, 
    toggleMessageBoxVisibility,
    getAllTopics,
    toggleTopicByIdSorting,
    toggleTopicByNameSorting
} from "../~store/actions/actions";

import {Button} from "reactstrap";
import TopicItem from "./topic-item/TopicItem";
import MessageBox from "src/app/shared/components/message-boxes/message-box/MessageBox";

import copy from "copy-to-clipboard";
import getScript from "../../shared/helpers/script-helper/ScriptHelper";

import "./TopicList.css";
import Loader from "src/app/shared/components/loader/Loader";
import { useEffect } from "react";
import history from "src/~store/history";
import { PermissionType } from "src/app/shared/components/user-context/models/PermissionType";
import i18n from "src/app/shared/localization/i18n";
import { SortingOption } from "src/app/nlp/~store/models/enums/SortingOption"

type ReduxType = ReturnType<typeof mapStateToProps> & ITopicListProps;

const TopicList = (props: ReduxType) => {

    const canUserManageNlp = (props.permissionList.indexOf(PermissionType.NLP_Manage) > -1);
    const canUserSearchNlp = (props.permissionList.indexOf(PermissionType.NLP_Search) > -1);

    useEffect(() => {
        props.getAllTopics()
        return () => {
            props.cancelCreateTopic();
        }
    }, []);

    const {topics, isCreatingTopic, isMessageBoxVisible} = props;

    const _onCreateTopicHandler = (): void => {
        props.createTopic();
    };

    const _onCancelCreateTopicHandler = (): void => {
        props.cancelCreateTopic();
    };

    const _onSelectTopicHandler = (topicExternalId: string): void => {
        if(canUserManageNlp) {
            history.push(`/nlp/edit-topic/${topicExternalId}`, {topicExternalId});
        }
        else {
            _onSearchTopicHandler(props.topics.find(t => t.topicExternalId === topicExternalId)?.name || "");
        }
    };

    const _onSearchTopicHandler = (topicName: string) => {
        let encodedTopicname = encodeURIComponent(encodeURIComponent(topicName));
        history.push(`/nlp/search/${encodedTopicname}`);
        //navigate(`/nlp/search/${topicName}`);  uncomment this in new MUI
    }

    const _onSaveTopicHandler = (topicExternalId: string, newTopicName: string, parametersJson: string): void => {
        props.saveTopic(topicExternalId, newTopicName, parametersJson);
    };

    const _onDeleteTopicHandler = (topicExternalId: string): void => {
        if (window.confirm(i18n.t('nlp.messageBoxDeleteTopicMessage'))) {
            props.deleteTopic(topicExternalId)
        }
    };

    const _onGetScript = (topicExternalId: string, url: string): void => {
        copy(getScript(topicExternalId, url));
        props.setMessageBoxData({
            title: i18n.t('nlp.messageBoxGetScriptTitle'),
            message: i18n.t('nlp.messageBoxGetScriptMessage'),
            type: MessageBoxType.Default
        });
        props.toggleMessageBoxVisibility();
    };

    return (
        <div className="topic-list__container">
                <div className="topic-list__header-buttons">
                    {canUserManageNlp && <Button className="topic-list__button" disabled={isCreatingTopic} onClick={_onCreateTopicHandler}>
                    {i18n.t('nlp.createNewTopic')}
                    </Button>}
                    {isCreatingTopic && (
                        <Button className="topic-list__button topic-list__button-cancel-create" onClick={_onCancelCreateTopicHandler}>
                            {i18n.t('common.cancel')}
                        </Button>
                    )}
                </div>
            <thead className="topic-table">
                <tr>
                    <th>
                        <span className="topics-list__id" onClick={() => props.toggleTopicByIdSorting()}>{i18n.t('common.id')}</span>
                        <span>{props.isSortingByTopicId === SortingOption.Increase ? "⯈" : props.isSortingByTopicId === SortingOption.Decrease ? "⯆" : ""}</span>
                    </th>
                    <th>
                        <span className="topics-list__id" onClick={() => props.toggleTopicByNameSorting()}>{i18n.t('common.name')}</span>
                        <span>{props.isSortingByTopicName === SortingOption.Increase ? "⯈" : props.isSortingByTopicName === SortingOption.Decrease ? "⯆" : ""}</span>
                    </th>
                </tr>
            </thead>
            {props.isOperationInProgress ? <Loader /> : <div className="topic-list__items">
                {topics.map(topic => (
                    <TopicItem
                        key={topic.topicId}
                        topic={topic}
                        canUserManageTopic={canUserManageNlp}
                        canUserSearchTopic={canUserSearchNlp}
                        onSelectTopic={_onSelectTopicHandler}
                        onSaveTopic={_onSaveTopicHandler}
                        onDeleteTopic={_onDeleteTopicHandler}
                        onGetScript={_onGetScript}
                        onSearchHandler={_onSearchTopicHandler}
                    />
                ))}
            </div>}
            {isMessageBoxVisible && <MessageBox />}
        </div>
    );
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        topics: topicsSelector(state),
        isCreatingTopic: isCreatingTopicSelector(state),
        isMessageBoxVisible: isMessageBoxVisibleSelector(state),
        isOperationInProgress: isOperationInProgressSelector(state),
        permissionList: state.userContextState.permissions.permissionList,
        isSortingByTopicId: isSortingByTopicIdSelector(state),
        isSortingByTopicName: isSortingByTopicNameSelector(state),
    };
};

const mapDispatchToProps = (dispatch: any): ITopicListProps => {
    return {
        createTopic: () => dispatch(createTopic()),
        cancelCreateTopic: () => dispatch(cancelCreateTopic()),
        saveTopic: (
            topicExternalId: string, 
            topicName: string,
            parametersJson: string
        ) => dispatch(saveTopic(topicExternalId, topicName, parametersJson)),
        deleteTopic: (
            topicExternalId: string
        ) => dispatch(deleteTopic(topicExternalId)),
        setMessageBoxData: (
            data: {
                title: string,
                message: string,
                className?: string,
                type?: MessageBoxType,
                onClose?(): void,
                onConfirm?(): void,
                onReject?(): void,
            }
        ) => dispatch(setMessageBoxData(data)),
        toggleMessageBoxVisibility: () => dispatch(toggleMessageBoxVisibility()),
        getAllTopics: () => dispatch(getAllTopics()),
        toggleTopicByIdSorting: () => dispatch(toggleTopicByIdSorting()),
        toggleTopicByNameSorting: () => dispatch(toggleTopicByNameSorting()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicList);
