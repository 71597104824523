const HOST_KB_API_ROUTE = `${process.env.REACT_APP_KB_API_ROUTE}`;

const TOPIC_API_ROUTE = `${HOST_KB_API_ROUTE}/topic`;
export const GET_TOPICS_API_ROUTE = `${TOPIC_API_ROUTE}/get-topics`;
export const SAVE_TOPIC_API_ROUTE = `${TOPIC_API_ROUTE}/save-topic`;
export const TRY_IMPORT_TOPIC_API_ROUTE = `${TOPIC_API_ROUTE}/try-import-topic`;
export const DELETE_TOPIC_API_ROUTE = `${TOPIC_API_ROUTE}/delete-topic`;
export const GET_TOPIC_BY_EXTERNAL_ID_API_ROUTE = `${TOPIC_API_ROUTE}/get-topic-by-external-id`;

const TOPIC_PAIR_API_ROUTE = `${HOST_KB_API_ROUTE}/topic-pair`;
export const SAVE_TOPIC_PAIR_API_ROUTE = `${TOPIC_PAIR_API_ROUTE}/save-topic-pair`;
export const DELETE_TOPIC_PAIR_API_ROUTE = `${TOPIC_PAIR_API_ROUTE}/delete-topic-pair`;
export const CHANGE_TOPIC_PAIR_STATUS_API_ROUTE = `${TOPIC_PAIR_API_ROUTE}/change-topic-pair-status`;
export const TRANSFER_QUESTION_TO_TOPIC_PAIR_API_ROUTE = `${TOPIC_PAIR_API_ROUTE}/transfer-question-to-topic-pair`;
export const GET_TOPIC_BY_TOPIC_NAME_WITH_FILTER_API_ROUTE = `${TOPIC_API_ROUTE}/get-topic-by-topic-name-with-filter`;
export const GET_TOPIC_PAIR_CATEGORIES_BY_TOPIC_NAME_API_ROUTE = `${TOPIC_PAIR_API_ROUTE}/get-topic-pair-categories_by_topic_name?topicname=`;
export const GET_QUESTIONS_BY_TOPIC_NAME_API_ROUTE = `${TOPIC_PAIR_API_ROUTE}/get-questions-by-topic-name`;

export const EXPORT_PAIRS_API_ROUTE = `${TOPIC_API_ROUTE}/export-pairs`;
export const IMPORT_PAIRS_API_ROUTE = `${TOPIC_PAIR_API_ROUTE}/import-pairs`;

const QUESTION_API_ROUTE = `${HOST_KB_API_ROUTE}/question`;
export const PUBLISH_QUESTIONS_API_ROUTE = `${QUESTION_API_ROUTE}/publish-questions`;
export const GET_SYNONYMS = `${QUESTION_API_ROUTE}/get-synonyms`;
export const SAVE_SYNONYMS = `${QUESTION_API_ROUTE}/save-synonyms`;
export const GENERATE_QUESTIONS_API_ROUTE = `${QUESTION_API_ROUTE}/generate-questions`;

const ANSWER_API_ROUTE = `${HOST_KB_API_ROUTE}/answer`;
export const SEARCH_ANSWER_API_ROUTE = `${ANSWER_API_ROUTE}/search-answer`;
export const LOG_OPERATOR_ANSWER_API_ROUTE = `${ANSWER_API_ROUTE}/log-operator-answer`;
