import { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory  } from 'react-router-dom';
import { NavigationDirection } from './NavigationDirection';

const useNavigationDirection = (): NavigationDirection | null => {
  const location = useLocation();
  const history = useHistory();
  const [direction, setDirection] = useState<NavigationDirection | null>(null);
  const prevLocation = useRef(location);

  useEffect(() => {
    if (prevLocation.current !== location) {
      if (history.action === 'PUSH') {
        setDirection(NavigationDirection.Forward);
      } else if (history.action === 'POP') {
        setDirection(NavigationDirection.Backward);
      }
    }
    prevLocation.current = location;
  }, [location, history.action]);

  return direction;
};

export default useNavigationDirection;