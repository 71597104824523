import React, { useState } from 'react';
import { connect } from 'react-redux';
import { GET_AVATAR_BY_CONTACT_ID_API_ROUTE } from 'src/app/contact/~api/apiRoutes';
import { IContact } from 'src/app/contact/~store/models/Contact';
import { GET_AVATAR_BY_ID_API_ROUTE } from 'src/app/operator/~api/apiRoutes';
import {  DEFAULT_OPERATOR_AVATAR_ID } from 'src/app/operator/~store/models/ModelsConstants';
import { IOperator } from 'src/app/operator/~store/models/Operator';
import { TagName, TagType } from '../../~types/models/SearchingTag';
import { ISuggetionProps } from './~types/SuggestionProps';
import { ISuggetionReduxProps } from './~types/SuggestionReduxProps';
import { getExistsTagNames, getExistsTagValuesByTagName } from 'src/app/chats/~store/actions/actions';
import { chatTagNamesSelector, chatTagValuesSelector } from 'src/app/chats/~store/selector';
import { IApplicationState } from 'src/~store/models/ApplicationState';
import { Avatar } from '@mui/material';
import ContactIcon from '@mui/icons-material/AccountCircle';
import OperatorIcon from '@mui/icons-material/SupportAgent'
import { ContactTagValue } from '../../../../helpers/contact-tag-value/ContactTagValue';
import i18n from "src/app/shared/localization/i18n";

type ReduxType = ReturnType<typeof mapStateToProps> & ISuggetionReduxProps;

const Suggestion = (props: ISuggetionProps & ReduxType) => {
    const [isChatTagsShown, setIsChatTagsShown] = useState(false);
    const userInStorage = sessionStorage.getItem("user");
    const customerId = userInStorage === null ? "" : JSON.parse(userInStorage.toString()).customerId;

    const _createSuggestion = (value: string, key: number) => {
        if (props.suggestion.tagName === TagName.ChatTag && props.suggestion.tagType === TagType.OpeningTag)
        {
            return _createOpenTagForChatTags();
        } else {
            return (
                <div 
                    className={props.className}
                    key={key}
                    onClick={() => {
                        props.suggestion.value = value;
                        props.onSelected(props.suggestion);
                    }}>
                    {value}
                </div>
            );
        }
    }

    const _createOpenTagForChatTags = () => {
        return (
            <div
                onMouseEnter={() => { props.getExistsTagNames(); setIsChatTagsShown(true) }}
                onMouseLeave={() => setIsChatTagsShown(false)}
                className={props.className + " chat-tag-suggestion__dropdown"}
                >
                 {i18n.t('common.chatTag')}
                {_getTagTypesForChatTags()}
            </div>
        );
    }

    const hasRepeatingOpeningChatTag = (tag: string, tags: string[]): boolean => {
        for (var step = 0; step < tags.length; step += 3) {
            if (tags[step] === tag) return true;
        }
        return false;
    }

    const _getTagTypesForChatTags = () => {        
        if (isChatTagsShown) {
            return (
                <div
                    className="chat-tag-suggestion__dropdown-list">
                        {props.chatTags.map((s, i) => {
                            if (!hasRepeatingOpeningChatTag(s, props.searchingTag.map((val, i) => {return val.value}))) {
                                return <div
                                    className={"chat-tag-suggestion__dropdown-item"}
                                    key={i}
                                    onClick={() => {
                                        props.getExistsTagValuesByTagName(s);
                                        props.suggestion.value = s;
                                        props.onSelected(props.suggestion);
                                    }}>
                                    {s}
                                </div>
                            }})}
                </div>
            );
        }
    }

    const _createSuggestionFromOperator = (operator: IOperator, key: number) => {
        const imageUrl = 
            operator.avatarId !== DEFAULT_OPERATOR_AVATAR_ID ? 
            GET_AVATAR_BY_ID_API_ROUTE + operator.avatarId + "&customerId=" + customerId + "&cahceBypass=" + Math.random() : 
            "";
        return (
            <div
                className={props.operatorClassName}
                key={key}
                onClick={() => {
                    props.suggestion.value = operator.name.toString();
                    props.onSelected(props.suggestion);
                }} >
                <Avatar
                    className="suggestion-operator__avatar"
                    src={imageUrl}
                >
                    <OperatorIcon style={{ color: "#26a69a" }} />
                </Avatar>
                <div className="suggestion-operator__operator-info">
                    {operator.name}
                </div>
            </div>
        )
    }

    const _createSuggestionFromContact = (contact: IContact, key: number) => {
        const imageUrl = contact.avatarId === null ?
            '' :
            GET_AVATAR_BY_CONTACT_ID_API_ROUTE + contact.avatarId + "&customerId=" + customerId + "&cahceBypass=" + Math.random();

        return (
            <div
                className={props.operatorClassName}
                key={key}
                onClick={() => {
                    props.suggestion.value = ContactTagValue(contact);
                    props.onSelected(props.suggestion);
                }}>
                <Avatar
                    className="suggestion-contact__avatar"
                    src={imageUrl}
                >
                    <ContactIcon style={{ color: "#26a69a" }} />
                </Avatar>
                <div className="suggestion-contact__contact-info">
                    {contact.firstName} {contact.lastName} {`${(contact.chatName !== null && contact.chatName.length > 0) ? `(${contact.chatName})`: ""}`}
                    {(contact.phone !== null && contact.phone.length > 0) && <div className="suggestion-contact__contact-phone">
                        +{contact.phone}
                    </div>}
                </div>
            </div>
        )
    }

    const hasRepeatingTag = (suggestion: string, openingTag: string, tags: string[]): boolean => {
        for (var step = 2; step < tags.length; step += 3) {
            if (tags[step] === suggestion && tags[step - 2] === openingTag) return true;
        }
        return false;
    }

    const _getSuggestion = () => {
        if(props.suggestion.tagType !== TagType.ClosingTag) {
            return _createSuggestion(props.suggestion.value, props.keyValue);
        } else {
            switch(props.suggestion.tagName) {
                case TagName.ChatTag:
                    return (<>
                        {props.chatTagValues?.filter(suggestion => {
                            if(props.searchString.length > 0) {
                                return (suggestion as string).toLowerCase().includes(props.searchString.toLowerCase());
                            } else {
                                return !hasRepeatingTag(suggestion, props.searchingTag[props.searchingTag.length - 2].value, props.searchingTag.map((val, i) => {return val.value}));
                            }
                        })
                        .map((val, i) => (
                            <div title={val} key={i} className="suggestion-chat-tag-value">
                                {_createSuggestion(val?.toString(), i)}
                            </div>)
                            )
                        }
                    </>)
                case TagName.Source:
                case TagName.Status:
                case TagName.Channel:
                case TagName.Date: {
                    return (
                        <div>
                            {props.suggestion.suggestions?.filter(suggestion => {
                                if(props.searchString.length > 0) {
                                    return (suggestion as string).toLowerCase().includes(props.searchString.toLowerCase());
                                } else {
                                    return true;
                                }
                            })
                            .map((val, i) => {
                                return _createSuggestion(val.toString(), i);
                            })}
                        </div>
                    )
                }
                case TagName.Subject: {
                    return (
                        <div>
                            {props.suggestion.suggestions?.filter(suggestion => {
                                if (props.searchString.length > 0) {
                                    return (suggestion as string).toLowerCase().includes(props.searchString.toLowerCase());
                                } else {
                                    return true;
                                }
                            })
                                .map((val, i) => {
                                    return _createSuggestion(val.toString(), i);
                                })}
                        </div>
                    )
                }
                case TagName.Setting: {
                    return (
                        <div>
                            {props.suggestion.suggestions?.filter(suggestion => {
                                if (props.searchString.length > 0) {
                                    return (suggestion as string).toLowerCase().includes(props.searchString.toLowerCase());
                                } else {
                                    return true;
                                }
                            })
                                .map((val, i) => {
                                    return _createSuggestion(val.toString(), i);
                                })}
                        </div>
                    )
                }
                case TagName.Operator: {
                    return (
                        <div>
                            {props.operators.filter(operator => {
                                if(props.searchString.length > 0) {
                                    if(operator.name !== null)
                                        return operator.name.toLowerCase().includes(props.searchString.toLowerCase());
                                    else
                                        return false;
                                } else {
                                    return true;
                                }
                            })
                            .slice(0, 5)
                            .map((operator, i) => {
                                return _createSuggestionFromOperator(operator, i);
                            })}
                        </div>
                    )
                }
                case TagName.Contact: {
                    return (
                        <div>
                            {props.contacts.map((contact, i) => {
                                return _createSuggestionFromContact(contact, i);
                            })}
                        </div>
                    )
                }
                case TagName.ChannelName: {
                    return (
                        <div>
                            {props.channels
                                .filter(channel => {
                                    if(props.searchString.length > 0) {
                                        if(channel.name !== null)
                                            return channel.name.toLowerCase().includes(props.searchString.toLowerCase());
                                        else
                                            return false;
                                    } else {
                                        return true;
                                    }
                                })
                                .map((channel, i) => {
                                    return _createSuggestion(channel.name, channel.channelId)
                                })
                            }
                        </div>
                    );
                }
                default:
                    return (<div></div>);
            }
        }
    }

    return (
        _getSuggestion()
    )
}

const mapStateToProps = (state: IApplicationState) => {
    return {
        chatTags: chatTagNamesSelector(state),
        chatTagValues: chatTagValuesSelector(state)
    };
}

const mapDispatchToProps = (dispatch: any): ISuggetionReduxProps => {
    return {
        getExistsTagNames: () => dispatch(getExistsTagNames()),
        getExistsTagValuesByTagName: (name: string) => dispatch(getExistsTagValuesByTagName(name))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Suggestion);