import React from "react";

export default class MarkupErrorBoundary extends React.Component<{text: string}> {
    readonly state: Readonly<{errorOccurred: boolean}> = {
        errorOccurred: false
    };
    
    constructor(props: any) {
      super(props);
      this.state = { errorOccurred: false };
    }
  
    componentDidCatch(error: any, errorInfo: any) {
      this.setState({
        errorOccurred: true
      });

      console.log("Invalid markup sended.", error);
    }
  
    render() {
      if (this.state.errorOccurred) {
        return <div>{this.props.text}</div>;
      }
  
      return this.props.children; 
    }
  }